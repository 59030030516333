import React, { useState, useEffect } from "react"
import { Col, Row } from "antd"
import Image from "../../get-image"

const cleaningTypes = ["regular", "moveInOut", "deep", "postConstruction"]

const typeToStringMap = {
  regular: "Regular",
  moveInOut: "Move In-Out",
  deep: "Deep",
  postConstruction: "Post Construction",
}

const conditionToStringMap = {
  good: "Good",
  average: "Average",
  bad: "Needs Extra Love",
}

const recurrenceToStringMap = {
  single: "One Time",
  monthly: "Every Month",
  twoWeeks: "Every 2 Weeks",
  weekly: "Every Week",
}

const HomeSummary = props => {
  const [extras, setExtras] = useState([])

  const {
    className,
    price,
    cleaningType,
    bedrooms,
    bathrooms,
    condition,
    zip,
    area,
    pets,
    fridge,
    oven,
    beds,
    // blinds,
    // windows,
    // baseBoards,
    recurrence,
    discount,
    hidePrice,
  } = props

  useEffect(() => {
    const newExtras = []
    if (pets > 0) {
      newExtras.push("Pets")
    }
    if (fridge) {
      newExtras.push("Fridge")
    }
    if (oven) {
      newExtras.push("Oven")
    }
    if (beds) {
      newExtras.push("Making beds")
    }
    // if (blinds) {
    //   newExtras.push("Blinds")
    // }
    // if (windows) {
    //   newExtras.push("Windows")
    // }
    // if (baseBoards) {
    //   newExtras.push("Base Boards")
    // }

    setExtras(newExtras)
  }, [pets, fridge, oven, beds])

  return (
    <Col
      xs={24}
      xl={{ span: 6, offset: 1 }}
      className={`home-calc-side fade-in ${className}`}
    >
      { className !== 'bottom' && <Image imageName="guarantee_img.png" className="trust-signal" />}
      <Row>
        <p className='service-summary'>Service Summary:</p>
      </Row>
      <div
        className={`home-calc-side__item ${
          cleaningType !== null ? "checked" : ""
        }`}
      >
        <span>Cleaning Type: </span>
        <span>
          {cleaningType !== null ? typeToStringMap[cleaningType] : `---`}
        </span>
      </div>

      <div
        className={`home-calc-side__item ${
          recurrence !== null ? "checked" : ""
        }`}
      >
        <span>Recurrence: </span>
        <span>
          {recurrence !== null ? recurrenceToStringMap[recurrence] : `---`}
        </span>
      </div>

      <div
        className={`home-calc-side__item ${bedrooms !== null ? "checked" : ""}`}
      >
        <span>Bedrooms: </span>
        <span>{bedrooms !== null ? bedrooms : `---`}</span>
      </div>

      <div
        className={`home-calc-side__item ${
          bathrooms !== null ? "checked" : ""
        }`}
      >
        <span>Bathrooms: </span>
        <span>{bathrooms !== null ? bathrooms : `---`}</span>
      </div>

      <div className={`home-calc-side__item ${area !== null ? "checked" : ""}`}>
        <span>Area: </span>
        <span>{area !== null ? `${area} sq.ft.` : `---`}</span>
      </div>

      <div className={`home-calc-side__item ${zip !== null ? "checked" : ""}`}>
        <span>Zip: </span>
        <span>{zip !== null ? zip : `---`}</span>
      </div>

      <div
        className={`home-calc-side__item ${
          condition !== null ? "checked" : ""
        }`}
      >
        <span>Home Condition: </span>
        <span>
          {condition !== null ? conditionToStringMap[condition] : `---`}
        </span>
      </div>

      <div
        className={`home-calc-side__item ${extras.length > 0 ? "checked" : ""}`}
      >
        <span>Add-ons: </span>
        <span>{extras.length === 0 ? "none" : extras.join(", ")}</span>
      </div>

      {!hidePrice && Number(area) <= 4500 && (
        <>
          <div className="home-calc-side__price">
            <span style={{ fontSize: 20 }}>Price: </span>
            {/* <div>
            <AttachMoneyIcon className="money" />
            </div> */}
            {/*<span className="home-calc-side__price--value">${price}</span>*/}
            <span className="home-calc-side__price--value">${price}</span>
          </div>
          <div className="home-cal-side__price--discount">
            {discount > 0 && <span>(Including {discount}% discount)</span>}
          </div>
        </>
      )}
      {Number(area) > 4500 && (<div className="home-calc__big-area">Please fill the form and we'll contact you back with a quote</div>)}
      {
        className !== 'bottom' && (<Col xs={24} style={{ marginTop: 45 }}>
          {" "}
          <p style={{ textAlign: "end", width: "100%" }}>Quality Guaranteed!</p>
        </Col>)
      }
    </Col>
  )
}

export default HomeSummary
