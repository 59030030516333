import React from 'react';
import { Row, Col, Form, Input } from "antd"
import AddLocationIcon from '@material-ui/icons/AddLocation';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SectionTitle from "./SectionTitle"

const emailValidation = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const phoneValidation = new RegExp(
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
);
const zipCodeValidation = new RegExp(/^\d{5}(?:[-\s]\d{4})?$/)

const CleaningAddress = ({refProp}) => {
  return (
    <Row gutter={24} className="home-calc__section fade-in" ref={refProp}>
      <SectionTitle title="Address to Clean">
        <LocationOnIcon/>
      </SectionTitle>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }}>
        <Form.Item
          name="address"
          label="Address"
          rules={[
            {
              required: true,
              message: 'Please enter your address'
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
      </Col>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }}>
        <Form.Item
          name="unit"
          label="Unit Number (if any)"
        >
          <Input type="text" />
        </Form.Item>
      </Col>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }}>
        <Form.Item
          name="cityToClean"
          label="City"
          rules={[
            {
              required: true,
              message: 'Please enter your city'
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
      </Col>
      <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }}>
        <Form.Item
          name="zipToClean"
          label="Zip Code"
          rules={[
            {
              required: true,
              message: 'Please enter a valid zip code',
              pattern: zipCodeValidation
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CleaningAddress;