import './CalculatorHome.less';
import React, { useState, useEffect, useRef } from 'react';
import { navigate } from "gatsby"
import { Row, Col, Form, Button } from 'antd';
import queryString from 'query-string';
import { useStripe } from "@stripe/react-stripe-js";
import { regular, moveInOut, minorPostConstruction, majorPostConstruction, pets } from "./data";
import HomeDetails from "../CalculatorComponents/HomeDetails"
import NextCta from "../CalculatorComponents/NextCta"
import HomeCondition from "../CalculatorComponents/HomeCondition"
import CleaningType from "../CalculatorComponents/CleaningType"
import AddOnServices from "../CalculatorComponents/AddOnServices"
import ContactDetails from "../CalculatorComponents/ContactDetails"
import AdditionalInfo from "../CalculatorComponents/AdditionalInfo"
import HomeSummary from "../CalculatorComponents/HomeSummary"
import MobilePriceDetails from "../CalculatorComponents/MobilePriceDetails"
import CardForm from "../CardForm";
import NetlifyHomeForm from "./NetlifyHomeForm"
import { GoogleEcommCheckout, GoogleEcommPurchase } from '../../utils'
import { encode, parseBoolValues } from "../../utils"
import Date from "../CalculatorComponents/Date"
import CleaningAddress from "../CalculatorComponents/CleaningAddress"


const cleaningTypes = ['regular', 'moveInOut', 'deep', 'postConstruction'];

const typeToPriceMap = {
  regular,
  moveInOut,
  deep: minorPostConstruction,
  postConstruction: majorPostConstruction
};

const conditionToIndexMap = {
  good: 0,
  average: 1,
  bad: 2
};

const getAreaIndex = (areaString) => {
  const area = parseInt(areaString);
  if (!area) { return null }
  if (area <= 1000) { return 0 }
  if (area > 1000 && area <= 1500) { return 1 }
  if (area > 1500 && area <= 2000) { return 2 }
  if (area > 2000 && area <= 2500) { return 3 }
  if (area > 2500 && area <= 3000) { return 4 }
  if (area > 3000 && area <= 3500) { return 5 }
  if (area > 3500 && area <= 4000) { return 6 }
  if (area > 4000 && area <= 4500) { return 7 }
  if (area > 4500) { return -1 }

  return null;
}

const getExtraPrice = (rooms = 0, basePrice = 0, additionalPrice = 0) => {
  if (rooms === 0) { return 0; }

  let extraRooms = 0;
  if (rooms > 1) {
    extraRooms = rooms - 1;
  }

  const price = basePrice + (extraRooms * additionalPrice);
  return price;
}

const calculateFullPrice = (basePrice = 0, addonsPrice = 0, discountPercent = 0) => {
  if (!discountPercent) {
    return basePrice + addonsPrice;
  }

  const pr = basePrice + addonsPrice;
  return (pr - (pr * (discountPercent / 100)));
}

const CalculatorHome = () => {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [stripeSubmitted, setStripeSubmitted] = useState(false);
  const [netlifySubmitted, setNetlifySubmitted] = useState(false);
  const [stripeError, setStripeError] = useState('');
  const [form] = Form.useForm();
  const [card, setCard] = useState(null);
  const [step, setStep] = useState(1);
  const [basePrice, setBasePrice] = useState(0);
  const [addonsPrice, setAddonsPrice] = useState(0);
  const [typePrices, setTypePrices] = useState([]);
  const [conditionIndex, setConditionIndex] = useState(null);
  const [areaIndex, setAreaIndex] = useState(null);
  const [cleaningType, setCleaningType] = useState(null);
  const [recurrence, setRecurrence] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [condition, setCondition] = useState(null);
  const [bedrooms, setBedrooms] = useState(null);
  const [bathrooms, setBathrooms] = useState(null);
  const [area, setArea] = useState(null);
  const [zip, setZip] = useState(null);
  const [petsAddon, setPetsAddon] = useState(0);
  const [fridgeAddon, setFridgeAddon] = useState(0);
  const [ovenAddon, setOvenAddon] = useState(0);
  const [bedsAddon, setBedsAddon] = useState(0);
  // const [blindsAddon, setBlindsAddon] = useState(0);
  // const [windowsAddon, setWindowsAddon] = useState(0);
  // const [baseBoardsAddon, setBaseBoardsAddon] = useState(0);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [stepName, setStepName] = useState('');
  const [fullPrice, setFullPrice] = useState(0);

  const detailsRef = useRef(null);
  const conditionRef = useRef(null);
  const addOnsRef = useRef(null);
  const dateRef = useRef(null);
  const userDetailsRef = useRef(null);
  const addressRef = useRef(null);
  const cardDetailsRef = useRef(null);
  const additionalInfoRef = useRef(null);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.type && cleaningTypes.includes(parsed.type)) {
      form.setFieldsValue({ cleaningType: parsed.type });
      setTypePrices(typeToPriceMap[parsed.type]);
      setStep(2);
    }
  }, []);

  useEffect(() => {
    if (areaIndex < 0) {
      return undefined;
    }
    if (typePrices && (conditionIndex !== null) && (areaIndex !== null)) {
      const newPrice = typePrices[areaIndex][conditionIndex];
      setBasePrice(newPrice);
    }
  }, [typePrices, conditionIndex, areaIndex]);

  useEffect(() => {
    const newAddonsPrice = petsAddon + ovenAddon + fridgeAddon + bedsAddon;
    setAddonsPrice(newAddonsPrice);
  }, [petsAddon, fridgeAddon, ovenAddon, bedsAddon]);

  useEffect(() => {
    setFullPrice(calculateFullPrice(basePrice, addonsPrice, discount))
  }, [bedrooms, bathrooms, addonsPrice, basePrice, discount])

  useEffect(() => {
    if ((netlifySubmitted && stripeSubmitted) || (netlifySubmitted && Number(area) > 4500)) {
      navigate('/thank-you')
    }
  }, [netlifySubmitted, stripeSubmitted])

  const onFinish = async (values) => {
    values.date = date;
    values.time = time;
    values.price = fullPrice;
    const parsedValues = parseBoolValues(values);

    if (!stripeSubmitted || !netlifySubmitted) {
      setLoading(true);
    }

    // Stripe
    if (!stripeSubmitted && Number(area) <= 4500) {
      let payload;

      try {
        payload = await stripe.createPaymentMethod({
          type: "card",
          card,
          billing_details: {
            email: values.email,
            name: `${values.firstName} ${values.lastName}`,
            phone: values.phone
          },
        });

        if (payload.error) {
          setLoading(false);
          return setStripeError(payload.error.message);
        }
      } catch (e) {
        return setLoading(false);
      }

      const payment_method = payload.paymentMethod.id;
      let description = { ...parsedValues };
      delete description.firstName;
      delete description.lastName;
      delete description.email;
      delete description.phone;
      delete description.pets;
      delete description.fridge;
      delete description.oven;
      delete description.beds;
      // delete description.blinds;
      // delete description.windows;
      // delete description.baseboards;
      delete description.additionalInfo;

      let descriptionText = '';
      Object.keys(description).forEach(key => {
        descriptionText += `${key}: ${description[key]} \n`;
      });

      await fetch("/.netlify/functions/stripe-customer", {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payment_method,
          email: values.email,
          name: `${values.firstName} ${values.lastName}`,
          phone: values.phone,
          description: descriptionText
        })
      })
        .then(response => {
          GoogleEcommPurchase(values.price, values.cleaningType)
          return response.text();
        })
        .then(text => {
          // if (json?.errorMessage && json?.errorMessage?.length) {
          //   setStripeError(json.errorMessage);
          // } else {
          //   setStripeError('');
          //   setStripeSubmitted(true);
          // }

          setStripeError('');
          setStripeSubmitted(true);
        });
    }

    // Netlify
    if (!netlifySubmitted) {
      await fetch(`/`, {
        method: `POST`,
        body: encode({
          'form-name': `Home Cleaning Form`,
          ...parsedValues,
        }),
      }).then(() => {
        setLoading(false);
        setNetlifySubmitted(true);
      }, (err) => {
        setLoading(false);
      })
        .catch(err => {
          setLoading(false);
        })
    }

  };
  const onCleaningTypeChange = (value) => {
    setTypePrices(typeToPriceMap[value]);
    setCleaningType(value);
    setRecurrence('single')
  }

  const onNextClick = () => {
    setTimeout(() => {
      if (step === 1) {
        if (!recurrence) { setRecurrence('single') }
        if (!cleaningType) {
          setCleaningType('regular');
          GoogleEcommCheckout('CleaningType', "regular")
          setTypePrices(typeToPriceMap['regular']);
        }else {
          GoogleEcommCheckout('CleaningType', cleaningType)
        }
        detailsRef.current.scrollIntoView()
      }
      if (step === 2) { GoogleEcommCheckout(step, cleaningType); conditionRef.current.scrollIntoView(); }
      if (step === 3) { GoogleEcommCheckout(step, cleaningType); addOnsRef.current.scrollIntoView(); }
      if (step === 4) { GoogleEcommCheckout(step, cleaningType); additionalInfoRef.current.scrollIntoView(); }
      if (step === 5) { GoogleEcommCheckout(step, cleaningType); userDetailsRef.current.scrollIntoView(); }
      if (step === 6) { GoogleEcommCheckout(step, cleaningType); addressRef.current.scrollIntoView(); }
      if (step === 7) { GoogleEcommCheckout(step, cleaningType); dateRef.current.scrollIntoView(); }
      if (step === 8) { GoogleEcommCheckout(step, cleaningType); cardDetailsRef.current.scrollIntoView(); }
    });
    setStep(step + 1);
  }

  const onCardNextClick = () => {
    const event = new CustomEvent('save-card');
    window.dispatchEvent(event);
  }

  const onCheckboxChange = (name, checked) => {
    let fieldValue = {};
    fieldValue[name] = checked;
    form.setFieldsValue(fieldValue);

    if (name === 'pets') {
      setPetsAddon(checked && areaIndex !== null ? pets[areaIndex] : 0);
    }

    if (name === 'fridge') {
      setFridgeAddon(checked ? 40 : 0);
    }

    if (name === 'oven') {
      setOvenAddon(checked ? 40 : 0);
    }

    if (name === 'beds') {
      setBedsAddon(checked ? getExtraPrice(bedrooms, 5, 5) : 0);
    }

    // if (name === 'blinds') {
    //   setBlindsAddon(checked ? getExtraPrice(bedrooms, 35, 15) : 0);
    // }
    //
    // if (name === 'windows') {
    //   setWindowsAddon(checked ? getExtraPrice(bedrooms, 15, 10) : 0);
    // }
    //
    // if (name === 'baseboards') {
    //   setBaseBoardsAddon(checked ? getExtraPrice(bedrooms, 15, 3) : 0);
    // }
  }

  const onDateChange = (date, dateString) => {
    setDate(dateString);
  }

  const onTimeChange = (timeString) => {
    setTime(timeString)
  }

  const onRecurrenceChange = (value) => {
    setRecurrence(value);
    form.setFieldsValue({ recurrence });
    if (value === 'single') { return setDiscount(0) }
    if (value === 'monthly') { return setDiscount(5) }
    if (value === 'twoWeeks') { return setDiscount(10) }
    if (value === 'weekly') { return setDiscount(15) }
  }

  const onHomeConditionChange = (value) => {
    setConditionIndex(conditionToIndexMap[value]);
    setCondition(value);
  }

  const onAreaChange = (e) => {
    setAreaIndex(getAreaIndex(e.target.value))
    setArea(e.target.value)
  }

  const onZipChange = (e) => {
    setZip(e.target.value);
  }

  const onBedroomsChange = (value) => {
    const newRoomsValue = parseInt(value, 10)

    if (bedsAddon) {
      setBedsAddon(getExtraPrice(newRoomsValue, 5, 5));
    }

    // if (blindsAddon) {
    //   setBlindsAddon(getExtraPrice(newRoomsValue, 35, 15));
    // }
    //
    // if (windowsAddon) {
    //   setWindowsAddon(getExtraPrice(newRoomsValue, 15, 10));
    // }
    //
    // if (baseBoardsAddon) {
    //   setBaseBoardsAddon(getExtraPrice(newRoomsValue, 15, 3));
    // }

    setBedrooms(newRoomsValue);
  }

  const onBathroomsChange = (value) => {
    setBathrooms(parseFloat(value));
  }

  const homeSummary = (className, hidePrice = false) => (
    <HomeSummary
      className={className}
      hidePrice={hidePrice}
      price={fullPrice}
      cleaningType={cleaningType}
      bedrooms={bedrooms}
      condition={condition}
      bathrooms={bathrooms}
      area={area}
      zip={zip}
      pets={petsAddon}
      fridge={fridgeAddon}
      oven={ovenAddon}
      beds={bedsAddon}
      // blinds={blindsAddon}
      // windows={windowsAddon}
      // baseBoards={baseBoardsAddon}
      recurrence={recurrence}
      discount={discount}
    />
  );

  return (
    <>
      <NetlifyHomeForm />
      <Form
        form={form}
        name="calculator-home"
        onFinish={onFinish}
        layout="vertical"
        className="home-calc"
      >

        <Row justify="center" className="calc-wrapper">
          <Col xs={22} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 12 }}>
            <CleaningType
              onChange={onCleaningTypeChange}
              typeSelected={cleaningType}
              recurrence={recurrence}
              onRecurrenceChange={onRecurrenceChange}
            />
            {step === 1 && <NextCta onClick={onNextClick} />}
            {
              step > 1 &&
              <HomeDetails
                onAreaChange={onAreaChange}
                onBedroomsChange={onBedroomsChange}
                onBathroomsChange={onBathroomsChange}
                onZipChange={onZipChange}
                refProp={detailsRef}
              />
            }
            {step === 2 && <NextCta onClick={onNextClick} />}

            {step > 2 && <HomeCondition onChange={onHomeConditionChange} refProp={conditionRef} />}
            {step === 3 && <NextCta onClick={onNextClick} />}

            {step > 3 && <AddOnServices onCheckboxChange={onCheckboxChange} refProp={addOnsRef} />}
            {step === 4 && <NextCta onClick={onNextClick} />}

            {step > 4 && <AdditionalInfo refProp={additionalInfoRef} />}
            {step === 5 && <NextCta onClick={onNextClick} />}

            {step > 5 && <ContactDetails refProp={userDetailsRef} />}
            {step === 6 && <NextCta onClick={onNextClick} />}

            {step > 6 && <CleaningAddress refProp={addressRef} />}
            {step === 7 && <NextCta onClick={onNextClick} />}

            {step > 7 && <Date onDateChange={onDateChange} onTimeChange={onTimeChange} refProp={dateRef} />}
            {step === 8 && <NextCta onClick={onNextClick} />}

            {step > 8 && <CardForm onNextClick={onNextClick} setCard={setCard} refProp={cardDetailsRef} errorMessage={stripeError} />}


            {
              step === 9 &&
              (<Row gutter={24} justify="center">
                <Col xs={12} md={{ span: 6 }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="primary-cta"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      loading={loading}
                    >
                      Submit Service Request
                    </Button>
                  </Form.Item>
                </Col>
              </Row>)
            }

          </Col>

          <Col xs={0} xl={{ span: 6, offset: 1 }} className="empty-placeholder"></Col>

          {homeSummary('side')}

          <MobilePriceDetails
            price={fullPrice}
            discount={discount}
            area={Number(area)}
            popupContent={homeSummary('bottom', true)}
          />

        </Row>
      </Form>
    </>
  );
};

export default CalculatorHome;