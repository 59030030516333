import React from 'react';
import { Row, Col, Form, Select, Input } from "antd"
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SectionTitle from "./SectionTitle"

const { Option } = Select;

const HomeDetails = ({onAreaChange, onBedroomsChange, onBathroomsChange, onZipChange, refProp}) => {
  return (
    <Row gutter={24} className="home-calc__section fade-in" ref={refProp}>
      <SectionTitle title="Home Details">
        <HomeOutlinedIcon />
      </SectionTitle>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }}>
        <Form.Item
          name="bedrooms"
          label="How many bedrooms?"
          rules={[
            {
              required: true,
              message: 'Please select number of bedrooms'
            },
          ]}
        >
          <Select
            placeholder="Number of bedrooms"
            allowClear
            onChange={onBedroomsChange}
          >
            <Option value="1">1 Bedroom</Option>
            <Option value="2">2 Bedrooms</Option>
            <Option value="3">3 Bedrooms</Option>
            <Option value="4">4 Bedrooms</Option>
            <Option value="5">5 Bedrooms</Option>
            <Option value="6">6 Bedrooms</Option>
          </Select>
        </Form.Item>
      </Col>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }}>
        <Form.Item
          name="bathrooms"
          label="How many bathrooms?"
          rules={[
            {
              required: true,
              message: 'Please select number of bathrooms'
            },
          ]}
        >
          <Select
            placeholder="Number of bathrooms"
            allowClear
            onChange={onBathroomsChange}
          >
            <Option value="1">1 Bathroom</Option>
            <Option value="1.5">1.5 Bathrooms</Option>
            <Option value="2">2 Bathrooms</Option>
            <Option value="2.5">2.5 Bathrooms</Option>
            <Option value="3">3 Bathrooms</Option>
            <Option value="3.5">3.5 Bathrooms</Option>
            <Option value="4">4 Bathrooms</Option>
            <Option value="5">5 Bathrooms</Option>
            <Option value="6">6 Bathrooms</Option>
          </Select>
        </Form.Item>
      </Col>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }}>
        <Form.Item
          name="area"
          label="Square Footage of Property"
          rules={[
            {
              required: true,
              message: 'Please enter the square footage'
            },
          ]}
        >
          <Input type="number" onBlur={onAreaChange}/>
        </Form.Item>
      </Col>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }}>
        <Form.Item
          name="zip"
          label="Zip Code"
          rules={[
            {
              required: true,
              message: 'Please enter a zip code'
            },
          ]}
        >
          <Input type="number" onBlur={onZipChange} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default HomeDetails;