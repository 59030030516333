import React, { useState } from 'react';
import { Col, Popover } from "antd";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import HelpIcon from '@material-ui/icons/Help';

const MobilePriceDetails = ({ price, area, discount, popupContent }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = visible => {
    setVisible(visible)
  };

  return (
    <Col xs={24} xl={0} className="mobile-price">
      <Popover
        content={popupContent}
        title="Details"
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        {
          area <= 4500 && (<>
            <div className='home-cal-side__price--price'>
               Price: ${price} <HelpIcon className="help" />
            </div>
            <div className="home-cal-side__price--discount">
              { discount > 0 && <span>(Including {discount}% discount)</span> }
            </div>
          </>)
        }

        {area > 4500 && (
          <div className="home-calc__big-area" style={{ padding: "0 16px", "text-align": "center"}}>Please fill the form and we'll contact you back with a quote</div>
        )}
      </Popover>
    </Col>
  );
};

export default MobilePriceDetails;