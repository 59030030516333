import React from 'react';
import { Row, Col, Button } from "antd"
import RightArrow from "../../images/arrow-right.png"

const NextCta = ({ onClick, disabled }) => {
  return (
    <Row gutter={24} justify="center" style={{ marginBottom: 40, marginTop: 40 }}>
      <Col xs={20} style={{ textAlign: 'center' }}>
        <Button disabled={disabled} onClick={onClick} type="primary" style={{ minWidth: 200 }} className="primary-cta">
          Next
          <img src={RightArrow} alt="service" className="right-arrow" />
        </Button>
      </Col>
    </Row>
  );
}

export default NextCta;