import './CardForm.less';
import React, { useMemo, useEffect } from 'react';
import { Button, Col, Form, Row } from "antd"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import PaymentIcon from '@material-ui/icons/Payment';
import SectionTitle from "../CalculatorComponents/SectionTitle"

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    []
  );

  return options;
};

const CardForm = ({ onNextClick, setCard, refProp, errorMessage }) => {
  const elements = useElements();
  const options = useOptions();

  useEffect(() => {
    const card = elements.getElement(CardElement);
    setCard(card);
  }, [elements]);

  return (
    <Row gutter={24} className="home-calc__section fade-in" ref={refProp}>
      <SectionTitle title="Card Details">
        <PaymentIcon />
      </SectionTitle>
      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item
          name="payment"
        >
          <CardElement
            options={options}
            onReady={() => {
              console.log("CardElement [ready]");
            }}
            onChange={event => {
              console.log("CardElement [change]", event);
            }}
            onBlur={() => {
              console.log("CardElement [blur]");
            }}
            onFocus={() => {
              console.log("CardElement [focus]");
            }}
          />
          <div className="card__error" style={{ color: 'rgb(255, 77, 79)' }}>
            {errorMessage}
          </div>
        </Form.Item>
      </Col>
      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
      <p>$0 charge today! <br/>We will securely store your card to be used for payment only after the cleaning service has been completed.</p>
      </Col>
    </Row>
  );
};

export default CardForm;