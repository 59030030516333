import React from "react"
import { Typography } from "antd"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CalculatorHome from "../../components/CalculatorHome"

const { Title } = Typography;

const ContactPage = () => (
  <Layout className="calculator">
    <SEO title="Calculator" />

    <Title className="page-title">
      Get a price and book online
    </Title>

    <CalculatorHome/>

  </Layout>
)

export default ContactPage
