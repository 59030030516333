import React from 'react';

// payment: undefined

const NetlifyHomeForm = () => {
  return (
    <form
      name="Home Cleaning Form"
      data-netlify="true"
      hidden
    >
      <input type="text" name="firstName" />
      <input type="text" name="lastName" />
      <input type="text" name="email" />
      <input type="text" name="phone" />
      <input type="text" name="zip" />
      <input type="text" name="area" />
      <input type="text" name="cleaningType" />
      <input type="text" name="condition" />
      <input type="text" name="recurrence" />
      <input type="text" name="bedrooms" />
      <input type="text" name="bathrooms" />
      <input type="text" name="additionalInfo" />
      <input type="text" name="beds" />
      {/*<input type="text" name="baseboards" />*/}
      {/*<input type="text" name="blinds" />*/}
      <input type="text" name="fridge" />
      <input type="text" name="oven" />
      <input type="text" name="pets" />
      {/*<input type="text" name="windows" />*/}
      <input type="text" name="date" />
      <input type="text" name="time" />
      <input type="text" name="address" />
      <input type="text" name="unit" />
      <input type="text" name="cityToClean" />
      <input type="text" name="zipToClean" />
      <input type="text" name="price" />
    </form>
  )
}

export default NetlifyHomeForm;