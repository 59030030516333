import React from 'react';
import { Row, Col, Form, Select, Typography } from "antd"
import SectionTitle from "./SectionTitle"
import SearchIcon from '@material-ui/icons/Search';

const { Option } = Select;
const { Title, Text } = Typography;

const HomeCondition = ({ onChange, refProp }) => {
  return (
    <Row gutter={24} className="home-calc__section fade-in" ref={refProp}>
      <SectionTitle title="Home Condition">
        <SearchIcon/>
      </SectionTitle>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item
          name="condition"
          rules={[
            {
              required: true,
              message: 'Please specify the home condition'
            },
          ]}
        >
          <Select
            placeholder="Home condition"
            allowClear
            onChange={onChange}
          >
            <Option value="good"><b>Good</b> <i>(kept up and has been cleaned within a month)</i></Option>
            <Option value="average"><b>Average</b> <i>(a little behind, but has been cleaned within 1-2 months)</i></Option>
            <Option value="bad"><b>Needs Extra Love</b> <i>(has build up on surfaces, hard water staining, and/or other similar conditions)</i></Option>
          </Select>
        </Form.Item>

        <Title level={3}>Condition Guidelines</Title>
        <ul>
          <li><Text>Good (kept up and has been cleaned within a month)</Text></li>
          <li><Text>Average (a little behind, but has been cleaned within 1-2 months)</Text></li>
          <li><Text>Needs Extra Love & Attention (has build up on surfaces, extra pet hair, hard water staining, and/or other similar conditions)</Text></li>
        </ul>
      </Col>
    </Row>
  );
};

export default HomeCondition;