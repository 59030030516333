import React from 'react';
import { Row, Col, Form, Select, Checkbox, Switch } from "antd"
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import SectionTitle from "./SectionTitle"

const AddOnServices = ({ onCheckboxChange, refProp }) => {
  return (
    <Row gutter={24} className="home-calc__section fade-in" ref={refProp}>
      <SectionTitle title="Add-on Services">
        <PlaylistAddCheckOutlinedIcon/>
      </SectionTitle>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item name="pets">
          <div className="calc-switch">
            <Switch id="1" onChange={(checked) => onCheckboxChange('pets', checked)} />
            <label htmlFor="1">Pets</label>
          </div>
        </Form.Item>
      </Col>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item name="fridge">
          <div className="calc-switch">
            <Switch id="2" onChange={(checked) => onCheckboxChange('fridge', checked)} />
            <label htmlFor="2">Fridge</label>
          </div>
        </Form.Item>
      </Col>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item name="oven">
          <div className="calc-switch">
            <Switch id="3" onChange={(checked) => onCheckboxChange('oven', checked)} />
            <label htmlFor="3">Oven</label>
          </div>
        </Form.Item>
      </Col>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item name="beds">
          <div className="calc-switch">
            <Switch id="4" onChange={(checked) => onCheckboxChange('beds', checked)} />
            <label htmlFor="4">Making beds</label>
          </div>
        </Form.Item>
      </Col>

      {/*<Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>*/}
      {/*  <Form.Item name="blinds">*/}
      {/*    <div className="calc-switch">*/}
      {/*      <Switch id="4" onChange={(checked) => onCheckboxChange('blinds', checked)} />*/}
      {/*      <label htmlFor="4">Blinds</label>*/}
      {/*    </div>*/}
      {/*  </Form.Item>*/}
      {/*</Col>*/}

      {/*<Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>*/}
      {/*  <Form.Item name="windows">*/}
      {/*    <div className="calc-switch">*/}
      {/*      <Switch id="5" onChange={(checked) => onCheckboxChange('windows', checked)} />*/}
      {/*      <label htmlFor="5">Interior Windows</label>*/}
      {/*    </div>*/}
      {/*  </Form.Item>*/}
      {/*</Col>*/}

      {/*<Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>*/}
      {/*  <Form.Item name="baseboards">*/}
      {/*    <div className="calc-switch">*/}
      {/*      <Switch id="6" onChange={(checked) => onCheckboxChange('baseboards', checked)} />*/}
      {/*      <label htmlFor="6">Baseboards</label>*/}
      {/*    </div>*/}
      {/*  </Form.Item>*/}
      {/*</Col>*/}
    </Row>
  );
};

export default AddOnServices;