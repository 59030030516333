import "../../styles/modal.less";
import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Form, Select, Typography, Button, Modal } from "antd"
import BubbleChartOutlinedIcon from '@material-ui/icons/BubbleChartOutlined';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SectionTitle from "./SectionTitle"
import AppContext from "../../app-context"
import CleaningCheckList from "../CleaningCheckList"

const { Option } = Select;
const { Title, Text } = Typography;

const CleaningType = ({ onChange, typeSelected, recurrence, onRecurrenceChange }) => {
  const [showExcluded, setShowExcluded] = useState(null);
  const [showFrequency, setShowFrequency] = useState(false);
  const [cleaningTypeContent, setCleaningTypeContent] = useState(<CleaningCheckList />);
  const { isModalVisible, setIsModalVisible } = useContext(AppContext);

  useEffect(() => {
    setCleaningTypeContent(<CleaningCheckList defaultSelected={ showExcluded ? 'excluded' : typeSelected} />)

    if (typeSelected === 'regular' || typeSelected === 'deep') {
      setShowFrequency(true);
    } else {
      setShowFrequency(false);
    }
  }, [typeSelected, showExcluded]);

  const showModal = (type) => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onExcludedClick = () => {
    setShowExcluded(true);
    setIsModalVisible(true);
  }

  return (
    <Row gutter={24} className="home-calc__section fade-in">
      <SectionTitle title="Cleaning Type">
        <BubbleChartOutlinedIcon/>
      </SectionTitle>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item
          name="cleaningType"
          rules={[
            {
              required: true,
              message: 'Please select a cleaning type'
            },
          ]}
        >
          <Select
            placeholder="Type of cleaning"
            allowClear
            onChange={onChange}
          >
            <Option value="regular">Regular Cleaning</Option>
            <Option value="moveInOut">Move-In/Move-Out Cleaning</Option>
            <Option value="deep">Deep Cleaning</Option>
            <Option value="postConstruction">Post-Construction Cleaning</Option>
          </Select>
        </Form.Item>

        <Title level={3}>What's included in each cleaning?</Title>
        <Text>
          Compare <Button onClick={() => showModal('checklist')} type="link" style={{ padding: '0 2px' }}>cleaning checklists here.</Button>
          Select a type of cleaning for addition details.
        </Text>

        <Title level={3}>Excluded services</Title>
        <Text>
          Some services and conditions are not included in any service. Review the
          {/*<Button onClick={() => showModal('excludedServices')} type="link" style={{ padding: '0 2px' }}> Excluded Services and Conditions</Button>*/}
          <Button onClick={onExcludedClick} type="link" style={{ padding: '0 2px' }}> Excluded Services and Conditions</Button>
          list to make sure you are able to get the clean you need.
        </Text>
      </Col>

      {
        showFrequency && (<>
          <SectionTitle title="Frequency of cleaning" className="renew-title">
            <AutorenewIcon />
          </SectionTitle>

          <Form.Item
            name="recurrence"
          />
          <Col xs={24}>
            <p>Save more when you book recurring services!</p>
          </Col>

          <Col xs={22} sm={11} lg={6}>
            <button
              type="button"
              className={`recurring-item ${recurrence === 'single' ? 'selected' : ''}`}
              onClick={() => onRecurrenceChange('single')}
            >
              <div>One time</div>
            </button>
          </Col>

          <Col xs={22} sm={11} lg={6}>
            <button
              type="button"
              className={`recurring-item ${recurrence === 'monthly' ? 'selected' : ''}`}
              onClick={() => onRecurrenceChange('monthly')}
            >
              <div>Monthly</div>
              <div>(5.00% off)</div>
            </button>
          </Col>

          <Col xs={22} sm={11} lg={6}>
            <button
              type="button"
              className={`recurring-item ${recurrence === 'twoWeeks' ? 'selected' : ''}`}
              onClick={() => onRecurrenceChange('twoWeeks')}
            >
              <div>Every 2 weeks</div>
              <div>(10.00% off)</div>
            </button>
          </Col>

          <Col xs={22} sm={11} lg={6}>
            <button
              type="button"
              className={`recurring-item ${recurrence === 'weekly' ? 'selected' : ''}`}
              onClick={() => onRecurrenceChange('weekly')}
            >
              <div>Weekly</div>
              <div>(15.00% off)</div>
            </button>
          </Col>
        </>
      )}

      <Modal
        title={!showExcluded ? `What's included` : `What's excluded`}
        className="home-calc__modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={() => setShowExcluded(false)}
        footer={null}
      >
        { cleaningTypeContent }
      </Modal>
    </Row>
  );
};

export default CleaningType;